<script>
import "@vueform/multiselect/themes/default.css";

import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config.json";
import Swal from "sweetalert2";

export default {
  page: {
    title: "User Details",
    meta: [{
      name: "description",
      content: appConfig.description,
    },],
  },
  data() {
    return {
      title: "User Details",
      items: [{
        text: "Users",
        href: "/",
      },
      {
        text: "User Details",
        active: true,
      },
      ],
      value: null,

      selectedUser: {},

      formData: {
        user_id: this.$route.params.id,
        region_id: 0,
        constituency_id: 0,
        station_id: 0,
        status_id: 0,
      },

      //assign station
      regions: "",
      constituencies: "",
      stations: "",

      //user
      userAssignedStations: [],
      userTransmittedResults: [],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  methods: {
    getSingleUser() {
      this.$store
        .dispatch("users/getSingleUser", this.$route.params.id)
        .then((response) => {
          this.selectedUser = response.data;
          console.log(response.data);

          // console.log("hshahsh", response);
        });
    },

    getRegions() {
      this.$store
        .dispatch("region/getRegionList")
        .then((res) => {
          this.regions = res.data;

          this.constituencies = "";
          this.stations = "";

          this.formData.constituency_id= 0;
          this.formData.station_id = 0;
        })
        .catch((err) => console.log(err));
    },

    getConstituencies() {
      this.$store
        .dispatch(
          "constituency/getConstituenciesByRegionId",
          this.formData.region_id
        )
        .then((res) => {
          this.formData.constituency_id = 0;
          this.formData.station_id = 0;
          this.stations = "";

          this.constituencies = res.data;
        })
        .catch((err) => console.log(err));
    },
    getStations() {
      this.$store
        .dispatch(
          "station/getStationsByConstituencyId",
          this.formData.constituency_id
        )
        .then((res) => {
          this.stations = res.data;

          this.formData.station_id = 0;
        })
        .catch((err) => console.log(err));
    },

    assignUserStation() {

     this.$store
          .dispatch("users/assignUserStation", this.formData)
         // eslint-disable-next-line no-unused-vars
          .then((response) => {
            this.getUserAssignedStations();
            Swal.fire("Success!", "Roles added successfully.", "success");

           // this.$router.go()
          }).catch((error) => {
        // get errors from state
        let response = error.response.data;
        this.displayToast('error', response);
      }).finally(() => (this.loadingAction = false));

      console.log(this.formData);
    },

    getUserAssignedStations() {
      this.$store
        .dispatch("users/getUserAssignedStations", this.$route.params.id)
        .then((res) => {
          this.userAssignedStations = res.data;
        })
        .catch((err) => console.log(err));
    },

    getUserTransmittedResults() {
      this.$store
        .dispatch("users/getUserTransmittedResults", this.$route.params.id)
        .then((res) => {
          console.log(res);
          this.userTransmittedResults = res.data;
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.getSingleUser();
    this.getUserAssignedStations();
    this.getUserTransmittedResults();
  },
  created() {
    this.getRegions();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xxl-3">
        <div class="card mb-3">
          <div class="card-body">
            <div class="table-card">
              <div class="mt-4 text-center">
                <h4 class="mb-1">{{ selectedUser.name }}</h4>
              </div>

              <div class="card-body border-top border-top-dashed p-1">
                <div></div>
              </div>
              <table class="table mb-0">
                <tbody>
                  <tr>
                    <td class="fw-medium">Name</td>
                    <td>{{ selectedUser.name }}</td>
                  </tr>
                  <tr>
                    <td class="fw-medium">Phone</td>
                    <td>{{ selectedUser.phone }}</td>
                  </tr>

                  <tr>
                    <td class="fw-medium">Role</td>
                    <td>
                      <span class="badge badge-soft-danger">{{
                          selectedUser.role_id
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="fw-medium">Status</td>
                    <td>
                      <span class="badge text-uppercase" :class="[
                        selectedUser.status_id === 1
                          ? 'badge-soft-success'
                          : 'badge-soft-danger',
                      ]">{{
    selectedUser.status_id === 1 ? "Active" : "Inactive"
}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="fw-medium">Last Login</td>
                    <td>{{ selectedUser.updated_at }}</td>
                  </tr>
                </tbody>
              </table>
              <!--end table-->
            </div>
          </div>
        </div>
        <!--end card-->
        <div class="card mb-3">
          <div class="card-body">
            <div class="d-flex mb-3">
              <h6 class="card-title mb-0 flex-grow-1">Assigned To</h6>
            </div>

            <form action="#" id="addform" @submit.prevent="assignUserStation">
              <div class="row">
                <div class="col-12">
                  <div class="mb-3">
                    <label for="ForminputState" class="form-label">Region</label>
                    <select id="ForminputState" class="form-select" v-model="formData.region_id"
                      @change="getConstituencies">
                      <option value="0" selected>Choose Region</option>
                      <option v-for="data in regions" :value="data.regionid" :key="data.regionid">
                        {{ data.region }}
                      </option>
                    </select>
                  </div>
                </div>
                <!--end col-->
                <div class="col-12">
                  <div class="mb-3">
                    <label for="ForminputState" class="form-label">Constituency</label>
                    <select id="ForminputState" class="form-select" v-model="formData.constituency_id"
                      @change="getStations">
                      <option value="0" selected>Choose Constituency</option>
                      <option v-for="data in constituencies" :value="data.id" :key="data.id">
                        {{ data.constname }}
                      </option>
                    </select>
                  </div>
                </div>
                <!--end col-->
                <div class="col-12">
                  <div class="mb-3">
                    <label for="ForminputState" class="form-label">Station</label>
                    <select id="ForminputState" class="form-select" v-model="formData.station_id">
                      <option value="0" selected>Const Station</option>
                      <option v-for="data in stations" :value="data.id" :key="data.id">
                        {{ data.stationname }}
                      </option>
                    </select>
                  </div>
                </div>
                <!--end col-->

                <div class="col-12">
                  <div class="mb-3">
                    <label for="ForminputState" class="form-label">Status</label>
                    <select id="ForminputState" class="form-select" v-model="formData.status_id">
                      <option value="0">Disabled</option>
                      <option value="1">Enabled</option>
                    </select>
                  </div>
                </div>
                <!--end col-->
                <div class="col-lg-12">
                  <div class="text-end">
                    <button type="submit" class="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
          </div>
        </div>
        <!--end card-->
      </div>
      <!---end col-->
      <div class="col-xxl-9">
        <div class="card">
          <div class="card-body">
            <div class="tab-content">
              <div class="tab-pane active" id="profile-1" role="tabpanel">
                <h6 class="card-title mb-4 pb-2">Transmitted Results</h6>
                <div class="table-responsive table-card">
                  <table class="table align-middle mb-0">
                    <thead class="table-light text-muted">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Station Code</th>
                        <th scope="col">Station Name</th>
                        <th scope="col">Constituency</th>
                        <th scope="col">Region</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in userTransmittedResults" :key="data.id">
                        <th scope="row">
                          {{ index + 1 }}
                        </th>
                        <td>{{ data.station.stationcode }}</td>
                        <td>{{ data.station.stationname }}</td>

                        <td>{{ data.station.constname }}</td>
                        <td>{{ data.station.regionname }}</td>
                        <td>{{ data.status_id }}</td>

                        <td>{{ data.prezndcvotes }}</td>
                        <td>{{ data.prezpinksheet }}</td>

                        <td>{{ data.palndcvotes }}</td>
                        <td>{{ data.palpinksheet }}</td>
                      </tr>

                    </tbody>
                  </table>
                  <!--end table-->
                </div>
              </div>
              <!--edn tab-pane-->
            </div>
            <!--end tab-content-->
          </div>
        </div>
        <!--end card-->

        <div class="card">
          <div class="card-body">
            <div class="tab-content">
              <div class="tab-pane active" id="profile-1" role="tabpanel">
                <h6 class="card-title mb-4 pb-2">Assigned Stations</h6>
                <div class="table-responsive table-card">
                  <table class="table align-middle mb-0">
                    <thead class="table-light text-muted">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Station Code</th>
                        <th scope="col">Station Name</th>
                        <th scope="col">Constituency</th>
                        <th scope="col">Region</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in userAssignedStations" :key="data.id">
                        <th scope="row">
                          {{ index + 1 }}
                        </th>
                        <td>{{ data.station.stationcode }}</td>
                        <td>{{ data.station.stationname }}</td>

                        <td>{{ data.station.constname }}</td>
                        <td>{{ data.station.regionname }}</td>
                        <td>{{ data.status_id }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <!--end table-->
                </div>
              </div>
              <!--edn tab-pane-->
            </div>
            <!--end tab-content-->
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
